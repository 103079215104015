<script>
import AbstractMenu from "@/layouts/AbstractMenu";
import { Logger } from "aws-amplify";
const logger = new Logger("HomeMenu");

var _isPro = () => {
  return false;
};
export default {
  name: "HomeMenu",

  extends: AbstractMenu,

  props: {
    pro: Boolean,
  },

  data: () => {
    return {
      // menu things
      rounded: null,
      inset: true,
      mini: 1,
      side: 1,
      section1: [],
    };
  },

  computed: {
    section0() {
      return [
        {
          text: "topmenu.professional",
          id: "SP.topMenu.pro",
          icon: "mdi-card-account-details-outline",
          link: {
            name: "PHome",
          },
          condition: () => {
            return !this.pro;
          },
        },
        {
          text: "topmenu.login",
          id: "A.topMenu.login",
          icon: "mdi-calendar-month",
          link: {
            name: "Login",
          },
          condition: () => {
            return !this.pro;
          },
        },
        {
          text: "topmenu.login",
          id: "SP.topMenu.login",
          icon: "mdi-calendar-month",
          link: {
            name: "PLogin",
          },
          condition: () => {
            return this.pro;
          },
        },
        /*
          {
            text: 'button.ok',
            id: 'SP.topMenu.login2',
            icon: 'mdi-calendar-month',
            link: {
              name: 'MiniWeb',
              params: {
                searchkey: 'kdw2',
              },
            },
            condition: () => {
              return !this.pro
            },
          },
          */
      ];
    },
    isPro() {
      _isPro = this.pro;
      return _isPro;
    },
  },

  watch: {
    pro: function (oldV, newV) {
      logger.info(`PRO old is ${oldV} and new is ${newV}`);
    },
    isPro: function (oldV, newV) {
      logger.info(`ISPRO old is ${oldV} and new is ${newV}`);
    },
  },

  created: () => {
    logger.info("HomeMenu Menu created");
  },
};
</script>
